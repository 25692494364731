import EgoForm from '@egodesign/form'
import { getCSRFTokenValue, handleModalErrorRetry, handleRetrySubmitButton, validateRecaptcha } from '../modules/form-helpers'
import { redirectToThankYouPage } from '../modules/tools'
import { metaLead } from '../modules/marketing/meta-api'
import { contactFormGTM } from '../modules/marketing/gtm'

export const clientForm = () => {
    const $form = document.querySelector('#client-form')
    if (!$form) return

    const $submitButton = $form.querySelector("button[type='submit']")
    const $modalErrorNode = document.querySelector('#client-form-error')

    const modalErrorInstance = handleModalErrorRetry($modalErrorNode, $submitButton)

    const csrf = getCSRFTokenValue($form)
    const formInstance = new EgoForm({
        element: $form,
        submitType: 'fetch',
        requestHeaders: {
            'X-CSRFToken': csrf,
        },
        customValidationMessages: {
            categories: {
                empty: 'Seleccioná al menos una opción',
            },
            actions: {
                empty: 'Seleccioná al menos una opción',
            },
        },
        onSuccess: (res) => {
            redirectToThankYouPage()
            res.json().then(({ data }) => {
                contactFormGTM({ element: $form, data })
                metaLead(data)
            })
        },
        onValidationError: (errorFieldsNames) => {
            if (errorFieldsNames.length) contactFormGTM({ element: $form, validationError: errorFieldsNames })
        },
        onError: () => {
            handleRetrySubmitButton($modalErrorNode)

            // Abrimos el modal si aún no está abierto
            if (!$modalErrorNode.classList.contains('--open')) modalErrorInstance.open()

            contactFormGTM({ element: $form })
        },
        onBeforeSubmit: () => validateRecaptcha(formInstance, csrf),
        preventSubmit: true,
    })

    // HANDLE CHECKBOXES
    const $actionCheckboxes = $form.querySelectorAll('.contactCheckAction input') // checkbox de Me interesa (Acciones)
    const $productCheckboxes = $form.querySelectorAll('.contactCheckProduct input') // checkbox de Productos

    if ($actionCheckboxes.length) {
        $productCheckboxes.forEach(($productCheckbox) => {
            const handlerChecks = () => {
                const productId = $productCheckbox.getAttribute('data-id')

                // Iterar sobre los checkboxes de Actions
                $actionCheckboxes.forEach(($actionCheckbox) => {
                    const productsToShow = $actionCheckbox.getAttribute('data-products')?.trim()

                    // Mostrar u ocultar el checkbox Actions según corresponda
                    if (!productsToShow.includes(productId)) {
                        if ($productCheckbox.checked) {
                            $actionCheckbox.parentElement.classList.add('--hidden')
                            $actionCheckbox.checked = false
                        } else {
                            $actionCheckbox.parentElement.classList.remove('--hidden')
                        }
                    }
                })
            }
            handlerChecks()
            $productCheckbox.addEventListener('change', () => {
                handlerChecks()
            })
        })
    }
}
