import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'
import { selectPromoGMT, viewPromoGTM } from '../modules/marketing/gtm'

export const heroHomeSlider = () => {
    new Swiper('.swiper.hero-home__swiper', {
        direction: 'horizontal',
        loop: true,
        sliderPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: (swiper) => {
                viewPromoGTM(swiper)
                selectPromoGMT(swiper)
            },
            slideChange: (swiper) => viewPromoGTM(swiper),
        },
    })
}
