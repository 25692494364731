import { storybookScrollTop } from '../components/storybook'
import { accordionDropdown, seeMoreDropdownItems } from '../components/dropdown'
import { formWebinars } from '../components/form-webinars'
import { formContact } from '../components/form-contact'
import { imageFullView } from '../components/image-full-view'
import { modalDetail } from '../components/modal-detail'
import { formDownload } from '../components/form-download'
import { textOnlySlider } from '../components/text-only'
import { textImgVerticalSlider } from '../components/text-img-vertical'
import { sliderWithImgNavPag, sliderWithImgSlider } from '../components/slider-with-img'
import { cardsFeaturesSlider } from '../components/cards-features'
import { heroHomeSlider } from '../components/hero-home'
import { sliderEventsSlider } from '../components/slider-events'
import { productSubcategoriesSlider } from '../components/product-subcategories'
import { bulletHighlightedSlider } from '../components/bullet-highlighted'
import { sliderLandingsSlider } from '../components/slider-landings'
import { landingsForm } from '../components/form-landings'
import { equipments } from '../components/equipments'
import { accordionDropdownImages } from '../components/dropdown-images'
import { sliderTextImgSlider } from '../components/slider-text-img'
import { sliderTitleTextSlider } from '../components/slider-title-text'
import { sliderThreeSlidesSlider } from '../components/slider-three-slides'
import { clientForm } from '../components/client-form'
import { handleEquipmentsFilter, handleSelectedFilterOption } from '../components/equipments-filter'
import { handleProductsPropertiesFilter } from '../components/equipment-list'
import { handlePropsFilter } from '../components/properties-filter'
import { surveyForm } from '../components/survey-form'
import { quickedLinkCardsSlider } from '../components/quicked-link-cards-slider'

storybookScrollTop() // storybook
formContact() // components: forms/form-contact.html
surveyForm() // components: forms/form-survey.html
formWebinars() // components: forms/form-webinars.html
formDownload() // components: forms/form-download.html
accordionDropdown() // components: preloaded/dropdown.html
modalDetail() // components: modals/modal-detail.html
imageFullView() // components: preloaded/image-full-view.html
textOnlySlider() // components: text-only/text-only.html
textImgVerticalSlider() // components: text-and-img/text-img-vertical.html
sliderWithImgSlider() // components: sliders/slider-with-images.html
sliderWithImgNavPag() // components: sliders/slider-with-images.html
cardsFeaturesSlider() // components: cards/cards-features.html
heroHomeSlider() // components: banners/hero-home.html
sliderEventsSlider() // components: sliders/slider-events.html
productSubcategoriesSlider() // components: items/product-subcategories.html
bulletHighlightedSlider() // components: bullets/bullet-highlighted.html
sliderLandingsSlider() // components: sliders/slider-landings.html
landingsForm() // components: forms/form-landings.html
equipments() // components: preloaded/equipments.html
accordionDropdownImages() // components: items/dropdown-images.html
sliderTextImgSlider() // components landings: sliders/slider-text-img.html
sliderTitleTextSlider() // components landings: sliders/slider-title-text.html
sliderThreeSlidesSlider() // components landings: sliders/slider-three-slides.html
seeMoreDropdownItems() // components: preloaded/dropdown.html
clientForm()
handleEquipmentsFilter()
handleProductsPropertiesFilter()
handlePropsFilter()
handleSelectedFilterOption()
quickedLinkCardsSlider()
